// Templates
vdViagem.TplHome = function(param,defaultParam) {

	vdViagem.CheckTopoCorretor(param);

	//Init Guide
	psLib.Init("#vd-calc-step3");

	var p = vdViagem.GetVar(),
		rtn = "",
		defaultParam = {
			gotoRecovery: "iniciarRecuperarAtendimento.ns",
			ufList: $("#fl-entry-origem option"),
			imgPath: typeof defaultParam != "undefined" ? defaultParam.imgPath : "../static/img/vd-viagem/",
			assetPath: typeof defaultParam != "undefined" ? defaultParam.assetPath : "../static/asset/vd-viagem/",
			forward: p.forwardVO
		};

	$.extend(defaultParam,param);

	var states = vdViagem.TplBuildGetStates(defaultParam.ufList);

	rtn += '<div id="vd-skin-container">';

	rtn += '<div id="vd-parallax-top">';

	//top
	rtn += '	<div class="vd-top">';
	rtn += '		<div class="ps-container">';
	rtn += '			<div class="vd-row">';
	if(vdViagem.IsTopoCorretor) {
		rtn += 				vdViagem.TplHeader("home",param);
	} else {
		rtn += '			<div class="ps-mod8 ps-sm-mod3 vd-sm-sessionHide vd-md-sessionHide">';
		rtn += '				<a href="./home.ns"><strong class="ps-logo ps-logo-white">Porto Seguro Viagem</strong></a>';
		rtn += '			</div>';
	}
	/* Link para recuperar senha
	rtn += '				<div class="vd-mod9 vd-sm-mod6 vd-right vd-sm-sessionHide" id="vd-topDefault">\
								<span class="vd-sm-hide">Resgate seguros contratados ou propostas incompletas:</span>\
								<a href="'+defaultParam.gotoRecovery+'" class="vd-btn vd-gray">\
									&Uacute;ltimas Cota&ccedil;&otilde;es&#160;\
									<span class="vd-icon vd-sm vd-restore-sm-white"></span>\
								</a>\
							</div>';*/
	rtn += '				<div class="ps-sm-mod9 vd-right vd-sm-sessionHide" id="vd-topDefault" style="display: none">';
	rtn += '					Resgate seguros contratados ou propostas incompletas:';
	rtn += '					<a href="#" class="vd-btn vd-gray">';
	rtn += '						&Uacute;ltimas Cota&ccedil;&otilde;es&#160;';
	rtn += '						<span class="vd-icon vd-sm vd-restore-sm-white"></span>';
	rtn += '					</a>';
	rtn += '				</div>';
	// rtn += '				<div class="vd-mod9 vd-sm-mod12" id="vd-topCalcStartup" style="padding: 0; display: none;">';
	// rtn += '					<div class="vd-row">';
	// // rtn += '						<div class="vd-mod1 vd-sm-mod2" style="padding: 0;">';
	// // rtn += '							<a href="javascript:;" class="vd-calcBack"><span class="vd-icon vd-md vd-arrowL-md-white"></span></a>';
	// // rtn += '						</div>';
	// rtn += '						<div class="vd-mod11 vd-sm-mod10" style="padding: 0;">';
	// rtn += '							<a href="javascript:;" class="vd-mod4 vd-md-mod4 vd-stepFeedback vd-scrollTo" data-scrollto="#vd-parallax-top" id="vd-top-step1">';
	// rtn += '								<div><label>ORIGEM</label> <strong id="vd-top-step1-origem"></strong></div>';
	// rtn += '								<div><label>DESTINO</label> <span id="vd-top-step1-destino"></span></div>';
	// rtn += '							</a>';
	// rtn += '							<a href="javascript:;" class="vd-mod4 vd-md-mod4 vd-stepFeedback vd-scrollTo" data-scrollto="#vd-calc-step3" id="vd-top-step2" style="display: none">';
	// // rtn += '								<div><label>PER&Iacute;ODO</label>	<strong id="vd-top-step2-periodo"></strong></div>';
	// rtn += '								<div><label>DATAS</label> <span id="vd-top-step3-datas"></span></div>';
	// rtn += '							</a>';
	// rtn += '							<a href="javascript:;" class="vd-mod4 vd-md-mod4 vd-stepFeedback vd-scrollTo" data-scrollto="#vd-calc-step4" id="vd-top-step3" style="display: none">';
	// rtn += '								<div><label>PESSOAS</label> <strong id="vd-top-step3-pessoas">1</strong></div>';
	// rtn += '								<div><label>IDADES</label> <span id="vd-top-step3-idades">At&eacute; 70: 1</span></div>';
	// rtn += '							</a>';
	// /*rtn += '							<a href="javascript:;" class="vd-mod3 vd-md-mod3 vd-stepFeedback vd-scrollTo" data-scrollto="#vd-calc-step5" id="vd-top-step4" style="display: none">';
	// rtn += '								<div><label>MOTIVO</label><br/> <strong id="vd-top-step4-motivo"></strong></div>';
	// rtn += '							</a>';*/
	// rtn += '						</div>';
	// rtn += '					</div>';
	// rtn += '				</div>';
	rtn += '				<div class="vd-sm-calcShow" id="vd-top-step5" style="display: none;">';
	rtn += '					<div class="vd-md-mod1 vd-sm-mod2" style="padding: 0;">';
	rtn += '						<a href="javascript:;" class="vd-calcBack"><span class="vd-icon vd-md vd-arrowL-md-white"></span></a>';
	rtn += '					</div>';
	rtn += '					<div class="vd-md-mod9 vd-sm-mod9">';
	rtn += '						<strong class="ps-logo">Porto Seguro Viagem</strong>';
	rtn += '					</div>';
	rtn += '				</div>';
	rtn += '			</div>';
	rtn += '		</div>';
	rtn += '	</div>';

	// wizard de calculo
	rtn += '	<div class="vd-novoCalculoDo" data-session="false">\
					<div class="vd-lp-highlight" id="vd-calc-startup">\
						<div class="ps-container">\
							<div class="ps-row">\
								<div class="ps-mod8 ps-md-mod9">\
									<h1>'+vdViagem.FillContent(vdViagem.DefaultID.lpSeoh2)+'</h1>\
									<h2>'+vdViagem.FillContent(vdViagem.DefaultID.lpSeoh2Title0)+'</h2>\
									<div class="vdo_startup">\
										<div class="vdo_startup__form">\
											<div class="vdo_startup__form_item">\
												<input type="text" name="vdo__destinos" class="ps-frm-entry viagem-select-multidestinos"\
												multiple="multiple"\
												autocomplete="off"\
												data-search-contain="false"\
												autofocus="autofocus"\
												data-selection-required="true"\
												data-focus-first-result="true"\
												data-min-length="1"\
												data-setvalue=".viagem-UfOrigem::SP"\
												placeholder="Para qual pa&iacute;s vai viajar?">\
												<div id="viagem-cttDestinos" data-clonestartidx="2"></div>\
												<div class="viagem-error-qtdeDestinoLimit" style="display: none;">\
													<div class="vd-form-control">\
														<input type="hidden" name="viagem-qtdeDestinoLimit" data-clonefield="#qtdeDestinoLimit"/>\
													</div>\
												</div>\
												<div class="viagem-paisEuropeu ps-pad-top--md" style="display: none;">\
													<p>Visitar&aacute; pa&iacute;ses Europeus?</p>\
													<input type="radio" name="vd-pais-europa" value="1" class="ps-frm-radio" id="vd-entry-paisEuropeu1" data-clonefield="#fl-entry-paisEuropeu1" checked>\
													<label class="ps-frm-radio" for="vd-entry-paisEuropeu1">N&atilde;o</label>\
													<input type="radio" name="vd-pais-europa" value="2" class="ps-frm-radio" id="vd-entry-paisEuropeu2" data-clonefield="#fl-entry-paisEuropeu2">\
													<label class="ps-frm-radio" for="vd-entry-paisEuropeu2">Sim</label>\
												</div>\
											</div>\
											<div clas="vdo_startup__form_item">\
												<label class="ps-frm-lbl ps-frm-lbl-internal">Quando vai viajar?</label>\
												<input type="text" name="vdo__datas" class="ps-frm-entry vdo_startup__form--calendar" data-calendardefaultdate="+1D" data-calendarmindate="-1M" placeholder="dd/mm/aaaa" data-calendarselect="setDataIda" id="dataVolta" value="" />\
											</div>\
											<div class="vdo_startup__form_item">\
												<label class="ps-frm-lbl ps-frm-lbl-internal">Quem ir&aacute;?</label>\
												<input type="text" name="vdo__passageiros" class="ps-frm-entry vdo_startup__form_passenger--total" placeholder="Passageiros" value="" data-showhide="show::.vdo_startup__form_passenger"/>\
												<div class="vdo_startup__form_passenger" style="display: none;">\
													<div class="vdo_startup__form_passenger_item">\
														<input type="hidden" class="vd-qtd-menor vd-qtdPassageiros" data-min="1" data-limit="20" data-condition=" [name=\'calculo.qtdPassagMenorSetentaAnos\']> 0 && [name=\'calculo.qtdPassagMenorSetentaAnos\'] <= 21" data-clonefield="#fl-entry-qtdPassagMenorSetentaAnos" value="1"/>\
														<div>\
															<span class="ps-ico ps-ico-boy ps-ico-sm"></span>\
														</div> \
														<div>\
															<strong>Passageiros</strong>\
															<br>\
															Com at&eacute; 70 anos\
														</div>\
														<div>\
															<a href="javascript:;" class="vd-sub disabled"><span class="ps-ico ps-ico-remove ps-ico-xs"></span></a>\
															<strong>0</strong>\
															<a href="javascript:;" class="vd-add"><span class="ps-ico ps-ico-add ps-ico-xs"></span></a>\
														</div>\
													</div>\
													<div class="vdo_startup__form_passenger_item">\
														<input type="hidden" class="vd-qtd-maior vd-qtdPassageiros vd-qtdPassageiros70" data-min="0" data-limit="20" data-condition=" [name=\'calculo.qtdPassagMenorSetentaAnos\']> 0 && [name=\'calculo.qtdPassagMenorSetentaAnos\'] <= 21" data-clonefield="#fl-entry-qtdPassagMaiorSetentaAnos" value=""/>\
														<div>\
															<span class="ps-ico ps-ico-talk-man ps-ico-sm"></span>\
														</div>\
														<div>\
															<strong>Passageiros</strong>\
															<br>\
															De 71 a 90 anos\
														</div>\
														<div>\
															<a href="javascript:;" class="vd-sub disabled"><span class="ps-ico ps-ico-remove ps-ico-xs"></span></a>\
															<strong>0</strong>\
															<a href="javascript:;" class="vd-add"><span class="ps-ico ps-ico-add ps-ico-xs"></span></a>\
														</div>\
													</div>\
													<div>\
														<a href="javascript:;" class="ps-btn" data-showhide="hide::.vdo_startup__form_passenger">Continuar</a>\
													</div>\
												</div>\
											</div>\
											<div class="vdo_startup__form_item">\
												<a href="javascript:;" class="ps-btn ps-btn-primary" data-exec="click:vdViagem.HomeValidateStep1(\'.vdo_startup__form\');">Cote agora</a>\
											</div>\
										</div>\
										<div class="ps-pad-top--md">\
											<a href="#vd-parallax-body" class="ps-scrollTo vd_startup__link">'+vdViagem.FillContent(vdViagem.DefaultID.lpSeoh2Desc0)+'</a>\
										</div>\
										<div class="'+(typeof window.skinBroker != "undefined" ? 'vdo_startup__partner' : '')+'">\
											<div class="fl-partner" style="display:'+(typeof window.skinBroker != "undefined" ? 'block' : 'none')+'">\
												<p>Uma parceria</p>\
												<img src="'+(typeof window.skinBroker != "undefined" ? window.skinBroker.brokerPath : '')+'">\
											</div>\
										</div>\
									</div>\
									<div class="vdo_startup__info">\
										<p>'+vdViagem.FillContent(vdViagem.DefaultID.lpTopLegalText0)+'</p>\
										<p>'+vdViagem.FillContent(vdViagem.DefaultID.lpTopLegalText1)+'</p>\
									</div>\
								</div>\
							</div>\
						</div>'; // container
	rtn += '			<div class="vd-lp-highlight-promo ps-hide">\
							<div class="ps-container">\
								<div class="ps-row">\
									<div class="ps-mod8 ps-sm-mod10 ps-sm-lspan1">\
										<h2>'+vdViagem.FillContent(vdViagem.DefaultID.lpPromoText0)+'</h2>\
										<h3>'+vdViagem.FillContent(vdViagem.DefaultID.lpPromoText1)+'</h3>\
									</div>\
								</div>\
							</div>\
						</div>';
	rtn += '		</div>'; //end highlight

	// rtn += '		<div class="vd-calc-step" id="vd-calc-step3" style="display: none;">';
	// rtn += '			<div class="vd-container">';
	// rtn += '				<h2 class="vd-step3-title">\
	// 							<span class="vd-icon vd-md vd-cal-md-gray"></span>\
	// 							<div class="vd-step3-depart">\
	// 								Data de <strong>Ida</strong>\
	// 								<small>Selecione a data em que sair&aacute; de viagem</small>\
	// 							</div>\
	// 							<div class="vd-step3-return" style="display: none;">\
	// 								Data de <strong>Volta</strong>\
	// 								<small>Selecione a data de chegada ao Brasil</small>\
	// 							</div>\
	// 						</h2>';
	// rtn += '				<div class="vd-row vd-step3-dates">\
	// 							<a href="javascript:;" class="'+(psLib.IsMobile ? 'vd-sm-mod6' : 'vd-mod6')+' vd-step3-sd-depart vd-step3-sd-active"\
	// 								data-showhide="show::#vd-cal-ida-ctt;hide::#vd-cal-volta-ctt;show::.vd-step3-depart;hide::.vd-step3-return"\
	// 								data-addremoveclass="remove::vd-step3-sd-active::.vd-step3-sd-return;add::vd-step3-sd-active::.vd-step3-sd-depart"\
	// 							>\
	// 								<div>\
	// 									<span>Ida</span>\
	// 									<span>DD/MM/AAA</span>\
	// 								</div>\
	// 							</a>\
	// 							<a href="javascript:;" class="'+(psLib.IsMobile ? 'vd-sm-mod6' : 'vd-mod6')+' vd-step3-sd-return"\
	// 								data-showhide="show::#vd-cal-volta-ctt;hide::#vd-cal-ida-ctt;show::.vd-step3-return;hide::.vd-step3-depart"\
	// 								data-addremoveclass="remove::vd-step3-sd-active::.vd-step3-sd-depart;add::vd-step3-sd-active::.vd-step3-sd-return"\
	// 							>\
	// 								<div>\
	// 									<span>Volta</span>\
	// 									<span>DD/MM/AAA</span>\
	// 								</div>\
	// 							</a>\
	// 						</div>\
	// 						<div class="vd-row">\
	// 							<div class="vd-mod12" id="vd-cal-ida-ctt">\
	// 								<!--div class="vd-row vd-hide vd-md-hide vd-sm-show">\
	// 									<h2 class="vd-step3-title vd-sm-mod12"><span class="vd-icon vd-md vd-cal-md-gray"></span> Data de <strong>Ida</strong> <small>Selecione a data em que sair&aacute; de viagem</small></h2>\
	// 								</div-->\
	// 								<div class="viagem-cal-ida" data-typetravel="#vd-cal-volta" id="vd-cal-ida" data-clonefield="#fl-entry-data-ida"></div>\
	// 							</div>\
	// 							<div class="vd-mod12" id="vd-cal-volta-ctt" style="display: none;">\
	// 								<!--div class="vd-row vd-hide vd-md-hide vd-sm-show">\
	// 									<h2 class="vd-step3-title vd-sm-mod12"><span class="vd-icon vd-md vd-cal-md-gray"></span> Data de <strong>Volta</strong> ao Brasil <small>Selecione a data de chegada ao Brasil</small></h2>\
	// 								</div-->\
	// 								<div class="viagem-cal-volta" data-typetravel="#vd-cal-ida" id="vd-cal-volta" data-clonefield="#fl-entry-data-volta"></div>\
	// 							</div>\
	// 						</div>';
	// rtn += ' 				<div class="vd-row" id="viagem-txtQuantidadeDias" style="display: none;">\
	// 							<div class="vd-mod6 vd-lspan3">\
	// 								<div class="ps-panel ps-panel-ico ps-panel-alert vd-subForm-alert">\
	// 									<span class="ps-ico ps-ico-alert"></span>\
	// 									<div class="ps-panel-ctt">\
	// 										O per&iacute;odo de viagem escolhido &eacute; de 01 dia, caso esteja tudo certo, basta continuar.\
	// 									</div>\
	// 								</div>\
	// 							</div>\
	// 						</div>\
	// 						<div class="ps-row vd-submitRow" id="vd-step3-submitRow" style="display:none">\
	// 							<div class="ps-mod8 ps-sm-mod4 ps-sm-lspan4">\
	// 								<a href="javascript:;" class="ps-btn ps-btn-primary vd-gotoStep4">PR&Oacute;XIMO</a>\
	// 							</div>\
	// 						</div>';
	// rtn += '			</div>'; //container
	// rtn += '		</div>'; // step3

	// rtn += '		<div class="vd-calc-step" id="vd-calc-step4" style="display: none;">';
	// rtn += '			<div class="vd-container">';
	// rtn += '				<div class="vd-row">';
	// rtn += '					<h2 class="vd-sm-mod12"><span class="vd-icon vd-md vd-person-md-gray"></span> Quem vai (incluindo o passageiro principal)?<br> <small class="ps-hide ps-sm-show">Indique o n&uacute;mero de passageiros (incluindo voc&ecirc;).<br> Considere a idade atual de todos, com base na data de hoje.</small></h2>';
	// rtn += '				</div>';
	// rtn += '				<div class="vd-row">';
	// rtn += '					<div class="vd-mod3 vd-lspan3 vd-center vd-sm-mod6" id="vd-qtdPassagMenorSetentaAnosBlock">';
	// rtn += '						<input type="hidden" class="vd-qtd-menor vd-qtdPassageiros" data-clonefield="#fl-entry-qtdPassagMenorSetentaAnos" value=""/>';	
	// rtn += '						<h3>AT&Eacute; 70 ANOS</h3>';
	// rtn += '						<div class="vd-fakeEntry" id="vd-qtdPassagMenorSetentaAnos">';
	// rtn += '							<strong>1</strong>';
	// rtn += '							<a href="javascript:;" class="vd-add" data-showhide="show::#vd-calc-step4 .vd-submitRow"><span class="ps-ico ps-ico-add ps-ico-sm"></span></a>';
	// rtn += '							<a href="javascript:;" class="vd-sub"><span class="ps-ico ps-ico-remove ps-ico-sm"></span></a>';
	// rtn += '						</div>';
	// rtn += '					</div>';
	// rtn += '					<div class="vd-mod3 vd-center vd-sm-mod6 viagem-qtdPassagMaiorSetentaAnos" id="vd-qtdPassagMaiorSetentaAnosBlock">';
	// rtn += '						<input type="hidden" class="vd-qtd-maior vd-qtdPassageiros" data-clonefield="#fl-entry-qtdPassagMaiorSetentaAnos" value=""/>';	
	// rtn += '						<h3>DE 71 A 90 ANOS</h3>';
	// rtn += '						<div class="vd-fakeEntry" id="vd-qtdPassagMaiorSetentaAnos">';
	// rtn += '							<strong>0</strong>';
	// rtn += '							<a href="javascript:;" class="vd-add" data-showhide="show::#vd-calc-step4 .vd-submitRow"><span class="ps-ico ps-ico-add ps-ico-sm"></span></a>';
	// rtn += '							<a href="javascript:;" class="vd-sub"><span class="ps-ico ps-ico-remove ps-ico-sm"></span></a>';
	// rtn += '						</div>';
	// rtn += '					</div>';
	// rtn += '					<div class="vd-mod3 vd-sm-mod10 vd-sm-lspan1 viagem-error-qtdePassageirosLimit" id="vd-qtdeLimite" style="display: none;">';
	// rtn += '						<span class="vd-icon vd-md vd-alert-md-red"></span> Limitado a <span></span> passageiros.';
	// rtn += '					</div>';
	// rtn += '				</div>';
	// rtn += '				<div class="vd-row" style="display: none;" id="vd-alertaApolice">';
	// rtn += '					<div class="vd-mod8 vd-lspan2">';
	// rtn += '						<span class="vd-icon vd-sm vd-calc-info-gray"></span> Ap&oacute;s a transmiss&atilde;o da Proposta a emiss&atilde;o da ap&oacute;lice ser&aacute; individual para cada passageiro.';
	// rtn += '					</div>';
	// rtn += '				</div>';
	// rtn += '				<div class="vd-row" id="vd-praticaEsportes">';
	// rtn += '					<div class="vd-mod8 vd-lspan2 vd-center vd-subForm">';
	// rtn += '						<h3>Praticar&aacute; <strong>esportes</strong> de aventura durante a viagem?</h3>';
	// rtn += '						<div class="vd-row">';
	// rtn += '							<input type="radio" name="fake.isPraticaAventura" value="false" id="vd-motivo-isPraticaAventura-false" class="ps-frm-radio" data-clonefield="#fl-entry-praticaAventura1" />';
	// rtn += '							<label class="ps-frm-radio" for="vd-motivo-isPraticaAventura-false">N&atilde;o</label>';
	// rtn += '							<input type="radio" name="fake.isPraticaAventura" value="true" id="vd-motivo-isPraticaAventura-true" class="ps-frm-radio" data-clonefield="#fl-entry-praticaAventura2"/>';
	// rtn += '							<label class="ps-frm-radio" for="vd-motivo-isPraticaAventura-true">Sim</label>';
	// rtn += '						</div>';
	// rtn += '						<hr class="vd-subForm-divisor" />';
	// rtn += '						<h3>Utilizar&aacute; motocicleta durante a viagem?</h3>';
	// rtn += '						<div class="vd-row">';
	// rtn += '							<input type="radio" name="fake.isUsoMotocicleta" value="false" id="vd-motivo-isUsoMotocicleta-false" class="ps-frm-radio" data-clonefield="#fl-entry-usoMotocicleta1" />';
	// rtn += '							<label class="ps-frm-radio" for="vd-motivo-isUsoMotocicleta-false">N&atilde;o</label>';
	// rtn += '							<input type="radio" name="fake.isUsoMotocicleta" value="true" id="vd-motivo-isUsoMotocicleta-true" class="ps-frm-radio" data-clonefield="#fl-entry-usoMotocicleta2"/>';
	// rtn += '							<label class="ps-frm-radio" for="vd-motivo-isUsoMotocicleta-true">Sim</label>';
	// rtn += '						</div>';
	// rtn += '						<div class="ps-panel ps-panel-ico ps-panel-alert vd-subForm-alert viagem-txtUsoMotocicleta" style="display:none;">\
	// 									<span class="ps-ico ps-ico-alert"></span>\
    //            							<div class="ps-panel-ctt">'+$(".viagem-txtUsoMotocicleta").text()+'</div>\
    //            						</div>';
	// rtn += '					</div>';
	// rtn += '				</div>';
	// /*rtn += '				<div class="vd-row vd-submitRow vd-center" style="display: none;">';
	// rtn += '					<a href="javascript:;" class="vd-btn vd-gotoStep5">PR&Oacute;XIMO</a>';
	// rtn += '				</div>';*/
	// rtn += '				<div class="ps-row vd-submitRow" style="display: none;">\
	// 							<div class="ps-mod8 ps-sm-mod4 ps-sm-lspan4">\
	// 								<a href="javascript:;" class="ps-btn ps-btn-primary" data-trigger="click:#fl-calc">CALCULAR</a>\
	// 							</div>';
	// rtn += '				</div>';
	// rtn += '			</div>'; // container
	// rtn += '		</div>';  //step4

	rtn +=	'		<div class="vd-calc-step" id="vd-calc-step2" style="display: none;">\
						<div class="ps-container">\
							<div class="ps-row">\
								<div class="ps-mod8 ps-sm-mod10 ps-sm-lspan1 ps-lg-mod8 ps-lg-lspan2">\
									<ul class="ps-wizard ps-wizard-icon">\
										<li class="ps-wizard-step ps-wizard-step-selected"><a href="javascript:;"><span class="ps-ico ps-glyph ps-ico-plane"></span> <span class="ps-hide ps-sm-show">Escolha do destino</span></a></li>\
										<li class="ps-wizard-step"><a href="javascript:;"><span class="ps-ico ps-glyph ps-ico-consulting"></span> <span class="ps-hide ps-sm-show">Planos</span></a></li>\
										<li class="ps-wizard-step"><a href="javascript:;"><span class="ps-ico ps-ico-add-user"></span><span class="ps-hide ps-sm-show">Dados do passageiro</span></a></li>\
										<li class="ps-wizard-step"><a href="javascript:;"><span class="ps-ico ps-ico-check"></span><span class="ps-hide ps-sm-show">Pagamento</span></a></li>\
									</ul>\
								</div>\
							</div>\
							<div class="ps-row vdo_steps">\
								<div class="ps-mod8 ps-sm-mod10 ps-sm-lspan1 ps-lg-mod8 ps-lg-lspan2">\
									<a href="javascript:;" class="vd-calcBack vdo_steps--back">\
										<span class="ps-ico ps-ico-arrow-left"></span>\
										Voltar\
									</a>\
									<h3 class="ps-title--md">Na sua viagem voc&ecirc;...</h3>\
									<div class="vdo_steps__coverage">\
										<span class="ps-ico ps-ico-landslide ps-ico-md"></span>\
										<label for="">Praticar&aacute; <strong>esportes de aventura</strong> durante a viagem?</label>\
										<input type="radio" name="fake.isPraticaAventura" value="false" id="vd-motivo-isPraticaAventura-false" class="ps-frm-radio" data-clonefield="#fl-entry-praticaAventura1" />\
										<label class="ps-frm-radio" for="vd-motivo-isPraticaAventura-false">N&atilde;o</label>\
										<input type="radio" name="fake.isPraticaAventura" value="true" id="vd-motivo-isPraticaAventura-true" class="ps-frm-radio" data-clonefield="#fl-entry-praticaAventura2"/>\
										<label class="ps-frm-radio" for="vd-motivo-isPraticaAventura-true">Sim</label>\
									</div>\
									<div class="vdo_steps__coverage ps-frm-row">\
										<span class="ps-ico ps-ico-helmet ps-ico-md"></span>\
										<label for="">Utilizar&aacute; <strong>motocicleta</strong> durante a viagem?</label>\
										<input type="radio" name="fake.isUsoMotocicleta" value="false" id="vd-motivo-isUsoMotocicleta-false" class="ps-frm-radio" data-clonefield="#fl-entry-usoMotocicleta1" />\
										<label class="ps-frm-radio" for="vd-motivo-isUsoMotocicleta-false">N&atilde;o</label>\
										<input type="radio" name="fake.isUsoMotocicleta" value="true" id="vd-motivo-isUsoMotocicleta-true" class="ps-frm-radio" data-clonefield="#fl-entry-usoMotocicleta2"/>\
										<label class="ps-frm-radio" for="vd-motivo-isUsoMotocicleta-true">Sim</label>\
										<div class="ps-panel ps-panel-ico ps-panel-alert vd-subForm-alert viagem-txtUsoMotocicleta" style="display:none;">\
											<span class="ps-ico ps-ico-alert"></span>\
											<div class="ps-panel-ctt">'+$(".viagem-txtUsoMotocicleta").text()+'</div>\
										</div>\
									</div>\
									<div>\
										<a href="javascript:;" class="ps-btn ps-btn-primary ps-btn-inline" data-trigger="click:#fl-calc">Continuar</a>\
									</div>\
								</div>\
							</div>\
						</div>\
					</div>'; //step2

	/*rtn += '		<div class="vd-calc-step" id="vd-calc-step5" style="display: none;">';
	rtn += '			<div class="vd-container">';
	rtn += '				<h2 class="vd-sm-mod12"><span class="vd-icon vd-md vd-query-md-gray"></span> Motivo da <strong>Viagem</strong></h2>';
	rtn += '				<div class="vd-row">';
	rtn += '					<div class="vd-mod3 vd-sm-mod6 vd-center">';
	rtn += '						<input type="radio" name="fake.motivoViagem" value="1" id="vd-ipt-motivo-lazer" class="vd-motivo" data-clonefield="#fl-entry-motivoViagem" data-showhide="show::#vd-calc-step5 .vd-submitRow"/>';
	rtn += '						<label class="vd-motivo" for="vd-ipt-motivo-lazer"><div><span class="vd-icon vd-lg vd-leisure-lg-blue"></span></div> Lazer</label>';
	rtn += '					</div>';
	rtn += '					<div class="vd-mod3 vd-sm-mod6 vd-center">';
	rtn += '						<input type="radio" name="fake.motivoViagem" value="2" id="vd-ipt-motivo-negocios" class="vd-motivo" data-clonefield="#fl-entry-motivoViagem" data-showhide="show::#vd-calc-step5 .vd-submitRow"/>';
	rtn += '						<label class="vd-motivo" for="vd-ipt-motivo-negocios"><div><span class="vd-icon vd-lg vd-bag-lg-blue"></span></div> Neg&oacute;cios</label>';
	rtn += '					</div>';
	rtn += '					<div class="vd-mod3 vd-sm-mod6 vd-center">';
	rtn += '						<input type="radio" name="fake.motivoViagem" value="3" id="vd-ipt-motivo-estudos" class="vd-motivo" data-clonefield="#fl-entry-motivoViagem" data-showhide="show::#vd-calc-step5 .vd-submitRow"/>';
	rtn += '						<label class="vd-motivo" for="vd-ipt-motivo-estudos"><div><span class="vd-icon vd-lg vd-study-lg-blue"></span></div> Estudos</label>';
	rtn += '					</div>';
	rtn += '					<div class="vd-mod3 vd-sm-mod6 vd-center">';
	rtn += '						<input type="radio" name="fake.motivoViagem" value="4" id="vd-ipt-motivo-visita" class="vd-motivo" data-clonefield="#fl-entry-motivoViagem" data-showhide="show::#vd-calc-step5 .vd-submitRow"/>';
	rtn += '						<label class="vd-motivo" for="vd-ipt-motivo-visita"><div><span class="vd-icon vd-lg vd-family-lg-blue"></span></div> Visita a amigo ou parente</label>';
	rtn += '					</div>';
	rtn += '				</div>';
	rtn += '				<div class="vd-row vd-submitRow vd-center" style="display: none;">';
	rtn += '					<a href="javascript:;" class="vd-btn vd-doCalculo">CALCULAR</a>';
	rtn += '				</div>';
	rtn += '			</div>'; //container
	rtn += '		</div>'; //step5*/

	rtn += '	</div>';

	rtn += '	<div class="vd-messageScreen vd-center" id="vd-calcLoading" style="display: none;">';
	rtn += '		<img src="'+defaultParam.imgPath+'/calculoLoading.gif" alt="Aguarde" />';
	rtn += '		<h3>processando <strong>informa&ccedil;&otilde;es...</strong></h3>';
	rtn += '		Isso vai levar apenas alguns segundos';
	rtn += '	</div>'; //loading

	rtn += '	<div class="vd-messageScreen vd-center" id="vd-calcError" style="display: none;">';
	rtn += '		<img src="'+defaultParam.imgPath+'/calculoErro.png" alt="Erro" />';
	rtn += '		<h3>Ops!</h3>';
	rtn += '		<p>N&atilde;o existem planos para o per&iacute;odo e/ou idade informados.</p>';
	rtn += '		<a href="javascript:location.reload();" class="vd-btn">VOLTAR</a>';
	rtn += '	</div>'; // error

	rtn += '	<div class="vd-calc-step vd-resultadoCalculo" id="vd-resultadoCalculo" style="display: none;" data-callbackajax="vdViagem.CalcReturn($this)">';

	rtn += '		<div class="vd-container">';
	rtn += '			<h2 class="vd-sm-mod12"><span class="vd-icon vd-md vd-qtt-md-gray"></span> <span class="vd-text">Planos para <strong></strong></span></h2>';
	rtn += '			<table class="vd-resultGrid">';
	rtn += '				<thead class="vd-md-hide vd-sm-hide"></thead>';
	rtn += '				<thead class="vd-hide vd-md-show vd-sm-show"></thead>';
	rtn += '				<tbody class="vd-md-hide vd-sm-hide"></tbody>';
	rtn += '				<tbody class="vd-hide vd-md-show vd-sm-show"></tbody>';
	rtn += '				<tfoot>';
	rtn += '					<tr>';
	rtn += '						<td></td>';
	rtn += '						<td colspan="1" class="vd-planInfo">';
	rtn += '							<a href="javacript:;" class="vd-row vd-hide vd-sm-show vd-showPlanDetail ps-open-modal" data-modal="#ModalDetalhePlano" style="margin-bottom: 20px;">';
	rtn += '								<div class="vd-sm-mod3 vd-md-mod1 vd-right"><span class="vd-icon vd-md vd-money-md-gray"></span></div>';
	rtn += '								<div class="vd-sm-mod9 vd-md-mod11 ">Confira todos os <u>valores e compare os planos</u>.</div>';
	rtn += '							</a>';
	/*rtn += '							<a href="javascript:;" class="vd-btn vd-sendCalcEmail vd-hide vd-sm-show">\
											<span class="vd-icon"></span>\
											Receber cota&ccedil;&atilde;o por e-mail\
										</a>';*/
	rtn += '							<small>Importante: As coberturas de seguro e servi&ccedil;os de assist&ecirc;ncia possuem restri&ccedil;&otilde;es para indeniza&ccedil;&atilde;o e/ou presta&ccedil;&atilde;o de servi&ccedil;os, com riscos exclu&iacute;dos. Consulte as <a href="'+vdViagem.DefaultLinks.CondicoesGerais+'" target="_blank">Condi&ccedil;&otilde;es Gerais</a> e <a href="'+vdViagem.DefaultLinks.ManualAssistencias+'" target="_blank">Manual de Assist&ecirc;ncia Emergencial</a> do plano escolhido</small><br/>';
	rtn += '							<small><sup>1</sup> Valores calculados em reais, considerando o c&acirc;mbio do dia da cota&ccedil;&atilde;o</small>';
	rtn += '							<small><sup>2</sup> Or&ccedil;amento v&aacute;lido somente para a data da cota&ccedil;&atilde;o</small>';
	rtn += '						</td>';
	rtn += '					</tr>';
	rtn += '				</tfoot>';
	rtn += '			</table>';
	rtn += '		</div>';
	/*rtn += '		<a href="javascript:;" class="vd-sm-hide vd-sendCalcEmail track-event">\
						<span class="vd-btn">\
							<span class="vd-tooltip">Receber cota&ccedil;&atilde;o por e-mail</span>\
						</span>\
					</a>\
					<div class="vd-sendCalcMailForm">\
						<div class="vd-container">\
							<a href="javascript:;" class="vd-hide vd-sm-show vd-sendCalcFormClose"></a>\
							<form id="vd-sendCalcMailForm" class="vd-form">\
								<div class="vd-mod2">\
									<h3>Receba cota&ccedil;&atilde;o por e-mail</h3>\
								</div>\
								<div class="vd-mod2 vd-form-group">\
									<label>Seu nome</label>\
									<input type="text" name="nomeProponente" class="vd-form-control vd-valid vd-cleanupText" />\
								</div>\
								<div class="vd-mod2 vd-form-group">\
									<label>Seu CPF</label>\
									<input type="hidden" name="numeroCpfCnpjProponente" value="" id="vd-cpf1">\
									<input type="hidden" name="digitoCnpjCpfProponente" value="" id="vd-cpf2">\
									<input type="tel" name="cpfSendEmail" placeholder="somente n&uacute;meros" class="vd-form-control vd-valid vd-cpfMask vd-split" data-split="-;#vd-cpf1;#vd-cpf2" />\
								</div>\
								<div class="vd-mod4 vd-form-group">\
									<label>Seu e-mail</label>\
									<input type="email" name="emailProponente" placeholder="" class="vd-form-control vd-valid vd-emailMask" />\
								</div>\
								<div class="vd-mod2 vd-form-group">\
									<label class="vd-sm-hide">&#160;</label>\
									<input type="submit" name="s" value="Ok" class="vd-btn  track-event" />\
								</div>\
							</form>\
						</div>\
					</div>';*/
	rtn += '	</div>';
	rtn += '</div>'; //parallax-top
	rtn += '<div id="vd-parallax-body">\
				<div class="vd-lp-block">\
					<div class="ps-container">\
						<h2 class="ps-title--xl">'+vdViagem.FillContent(vdViagem.DefaultID.lpSeoh3Title0)+'</h2>\
						<h3 class="ps-text--lg">'+vdViagem.FillContent(vdViagem.DefaultID.lpSeoh3Title1)+'</h3>\
						<div class="ps-row">\
							<div class="ps-mod8 ps-sm-mod10 ps-sm-lspan1 ps-sm-noGutter">\
								<div class="vd-lp-block-itens">\
									<div class="ps-sm-mod3 vd-lp-block-item">\
										<span class="ps-ico ps-glyph ps-ico-male2 ps-ico-lg"></span>\
										<h4>'+vdViagem.FillContent(vdViagem.DefaultID.lpSeoh4Title0)+'</h4>\
										<p>'+vdViagem.FillContent(vdViagem.DefaultID.lpSeoh4Desc0)+'</p>\
									</div>\
									<div class="ps-sm-mod3 vd-lp-block-item">\
										<span class="ps-ico ps-glyph ps-ico-plane ps-ico-lg"></span>\
										<h4>'+vdViagem.FillContent(vdViagem.DefaultID.lpSeoh4Title1)+'</h4>\
										<p>'+vdViagem.FillContent(vdViagem.DefaultID.lpSeoh4Desc1)+'</p>\
									</div>\
									<div class="ps-sm-mod3 vd-lp-block-item">\
										<span class="ps-ico ps-glyph ps-ico-plus ps-ico-lg"></span>\
										<h4>'+vdViagem.FillContent(vdViagem.DefaultID.lpSeoh4Title2)+'</h4>\
										<p>'+vdViagem.FillContent(vdViagem.DefaultID.lpSeoh4Desc2)+'</p>\
									</div>\
									<div class="ps-sm-mod3 vd-lp-block-item">\
										<span class="ps-ico ps-glyph ps-ico-luggage ps-ico-lg"></span>\
										<h4>'+vdViagem.FillContent(vdViagem.DefaultID.lpSeoh4Title3)+'</h4>\
										<p>'+vdViagem.FillContent(vdViagem.DefaultID.lpSeoh4Desc3)+'</p>\
									</div>\
								</div>\
							</div>\
						</div>\
					</div>\
				</div>\
				\
				<div class="vd-lp-block vd-lp-block-highlight">\
					<div class="ps-container">\
						<h2 class="ps-title--xl">'+vdViagem.FillContent(vdViagem.DefaultID.lpSeoh3BenefitsTitle0)+'</h2>\
						<h3 class="ps-text--lg">'+vdViagem.FillContent(vdViagem.DefaultID.lpSeoh3BenefitsTitle1)+'</h3>\
						<div class="ps-row">\
							<div class="ps-mod8 ps-sm-mod10 ps-sm-lspan1 ps-sm-noGutter">\
								<div class="vd-lp-block-itens">\
									<div class="ps-sm-mod4 vd-lp-block-item">\
										<div class="ps-card">\
											<div class="ps-card-content">\
												<span class="ps-ico ps-glyph ps-ico-paper-plane ps-ico-lg"></span>\
												<h4>'+vdViagem.FillContent(vdViagem.DefaultID.lpSeoh4BenefitsTitle0)+'</h4>\
												<p>'+vdViagem.FillContent(vdViagem.DefaultID.lpSeoh4BenefitsDesc0)+'</p>\
											</div>\
										</div>\
									</div>\
									<div class="ps-sm-mod4 vd-lp-block-item">\
										<div class="ps-card">\
											<div class="ps-card-content">\
												<span class="ps-ico ps-glyph ps-ico-luggage ps-ico-lg"></span>\
												<h4>'+vdViagem.FillContent(vdViagem.DefaultID.lpSeoh4BenefitsTitle1)+'</h4>\
												<p>'+vdViagem.FillContent(vdViagem.DefaultID.lpSeoh4BenefitsDesc1)+'</p>\
											</div>\
										</div>\
									</div>\
									<div class="ps-sm-mod4 vd-lp-block-item">\
										<div class="ps-card">\
											<div class="ps-card-content">\
												<span class="ps-ico ps-glyph ps-ico-pet-paw ps-ico-lg"></span>\
												<h4>'+vdViagem.FillContent(vdViagem.DefaultID.lpSeoh4BenefitsTitle2)+'</h4>\
												<p>'+vdViagem.FillContent(vdViagem.DefaultID.lpSeoh4BenefitsDesc2)+'</p>\
											</div>\
										</div>\
									</div>\
								</div>\
							</div>\
						</div>\
					</div>\
				</div>\
				<div class="vd-lp-block vd-lp-block-highlight-2">\
					<div class="ps-container">\
						<div class="ps-row">\
							<div class="ps-mod8 ps-sm-mod8 ps-sm-lspan2">\
								<div class="vd-lp-block-highlight-flex">\
									<div>\
										<h2>'+vdViagem.FillContent(vdViagem.DefaultID.lpSeoh3PortoCardTitle0)+'</h2>\
										<p>'+vdViagem.FillContent(vdViagem.DefaultID.lpSeoh4PortoCardDesc0)+'</p>\
										<div class="ps-mar-top--lg vd-lp-block-highlight__cta-area">\
											<a href="#skin-viagem" class="ps-scrollTo ps-btn ps-btn-inline ps-btn-primary ps-mar-bottom--sm">Cote agora</a><br>\
											<span class="ps-text--sm ps-text-weight--bold">Contrate em menos de 10 minutos</span>\
										</div>\
									</div>\
									<div>\
										<picture>\
											<source type="image/webp" srcset="'+defaultParam.assetPath+'/cartoes.webp">\
											<img src="'+defaultParam.assetPath+'/cartoes.png" alt="Cart&otilde;es de Cr&eacute;dito Porto Seguro" width="100%" height="auto">\
										</picture>\
									</div>\
								</div>\
							</div>\
						</div>\
					</div>\
				</div>\
				\
				<div class="vd-lp-block vd-lp-block-highlight vd-lp-block-plans">\
					<div class="ps-container-fluid">\
						<h2 class="ps-title--xl">'+vdViagem.FillContent(vdViagem.DefaultID.lpSeoh3PlansTitle0)+'</h2>\
						<h3 class="ps-text--lg">'+vdViagem.FillContent(vdViagem.DefaultID.lpSeoh3PlansTitle1)+'</h3>\
						<div class="ps-row">\
							<div class="ps-mod8 ps-sm-mod12">\
								<div class="vd-lp-block-itens">\
									<div class="ps-sm-mod3 vd-lp-block-item vd-lp-block-plans">\
										<div class="ps-card">\
											<picture>\
												<source type="image/webp" srcset="'+defaultParam.assetPath+'/plano-brasil.webp">\
												<img src="'+defaultParam.assetPath+'/plano-brasil.jpg" alt="Plano Am&eacute;rica" width="100%" height="auto">\
											</picture>\
											<div class="ps-card-content">\
												<h3>'+vdViagem.FillContent(vdViagem.DefaultID.lpSeoh4PlansTitle0)+'</h3>\
											</div>\
											<div class="ps-card-footer">\
												<a href="#skin-viagem" class="ps-scrollTo ps-btn">Cotar seguro</a>\
											</div>\
										</div>\
									</div>\
									<div class="ps-sm-mod3 vd-lp-block-item vd-lp-block-plans">\
										<div class="ps-card">\
											<span><img src="'+defaultParam.assetPath+'/plano-america.jpg" alt="Plano Brasil"></span>\
											<div class="ps-card-content">\
												<h3>'+vdViagem.FillContent(vdViagem.DefaultID.lpSeoh4PlansTitle1)+'</h3>\
											</div>\
											<div class="ps-card-footer">\
												<a href="#skin-viagem" class="ps-scrollTo ps-btn">Cotar seguro</a>\
											</div>\
										</div>\
									</div>\
									<div class="ps-sm-mod3 vd-lp-block-item vd-lp-block-plans">\
										<div class="ps-card">\
											<picture>\
												<source type="image/webp" srcset="'+defaultParam.assetPath+'/plano-europa.webp">\
												<img src="'+defaultParam.assetPath+'/plano-europa.jpg" alt="Plano Europa" width="100%" height="auto">\
											</picture>\
											<div class="ps-card-content">\
												<h3>'+vdViagem.FillContent(vdViagem.DefaultID.lpSeoh4PlansTitle2)+'</h3>\
											</div>\
											<div class="ps-card-footer">\
												<a href="#skin-viagem" class="ps-scrollTo ps-btn">Cotar seguro</a>\
											</div>\
										</div>\
									</div>\
									<div class="ps-sm-mod3 vd-lp-block-item vd-lp-block-plans">\
										<div class="ps-card">\
											<picture>\
												<source type="image/webp" srcset="'+defaultParam.assetPath+'/plano-mundo.webp">\
												<img src="'+defaultParam.assetPath+'/plano-mundo.jpg" alt="Plano Mundo" width="100%" height="auto">\
											</picture>\
											<div class="ps-card-content">\
												<h3>'+vdViagem.FillContent(vdViagem.DefaultID.lpSeoh4PlansTitle3)+'</h3>\
											</div>\
											<div class="ps-card-footer">\
												<a href="#skin-viagem" class="ps-scrollTo ps-btn">Cotar seguro</a>\
											</div>\
										</div>\
									</div>\
									<div class="ps-sm-mod3 vd-lp-block-item vd-lp-block-plans">\
										<div class="ps-card">\
											<picture>\
												<source type="image/webp" srcset="'+defaultParam.assetPath+'/plano-intercambio.webp">\
												<img src="'+defaultParam.assetPath+'/plano-intercambio.jpg" alt="Interc&acirc;mbio e Longa Perman&ecirc;ncia" width="100%" height="auto">\
											</picture>\
											<div class="ps-card-content">\
												<h3>'+vdViagem.FillContent(vdViagem.DefaultID.lpSeoh4PlansTitle4)+'</h3>\
											</div>\
											<div class="ps-card-footer">\
												<a href="#skin-viagem" class="ps-scrollTo ps-btn">Cotar seguro</a>\
											</div>\
										</div>\
									</div>\
								</div>\
							</div>\
						</div>\
					</div>\
				</div>\
				\
				<div class="vd-lp-block vd-lp-block-testimony">\
					<div class="ps-container">\
						<div class="ps-row">\
							<div class="ps-mod8 ps-sm-mod10 ps-sm-lspan1 ps-md-mod8 ps-md-lspan2">\
								<h2 class="ps-title--xl">O que dizem nossos clientes</h2>\
								<div class="ps-carousel" id="testimony__carousel" data-carouselconfig="{\'slidesToShow\': 1, \'arrows\': true, \'dots\': false, \'centerMode\': true, \'centerPadding\': 0, \'infinite\': true, \'responsive\': {\'breakpoint\': 768, \'settings\': {\'arrows\': true}}}">\
									<div class="ps-carousel-container">\
										<div class="ps-carousel-item">\
											<img src="'+defaultParam.imgPath+'/testimonial-diego-salgado.webp" alt="Fotografia Diego Salgado" class="testimony__img">\
											<strong class="testimony__name">Diego Salgado</strong>\
											<span class="testimony__description">atleta, influencer e cliente Seguro Viagem da Porto Seguro</span>\
											<img src="'+defaultParam.imgPath+'/testimonial-stars.svg" alt="Estrelas" class="testimony__stars">\
											<p class="testimony__block">\
												<span class="ps-ico ps-ico-quote1"></span>\
												Durante os dias que eu fiquei mal, precisei ser medicado e precisei tamb&eacute;m ficar hospedado (...). E todos os custos de medicamento e hospedagem foram pagos com o seguro da Porto, que cobre esse tipo de ocorr&ecirc;ncia. <strong>Foi uma tranquilidade no meio de todo esse furac&atilde;o de ficar doente longe de seu pa&iacute;s.</strong>\
												\
											</p>\
										</div>\
										<div class="ps-carousel-item">\
											<img src="'+defaultParam.imgPath+'/testimonial-gabriela-freitas.webp" alt="Fotografia Gabriela Freitas" class="testimony__img">\
											<strong class="testimony__name">Gabriela Freitas</strong>\
											<span class="testimony__description">cliente Seguro Viagem da Porto Seguro</span>\
											<img src="'+defaultParam.imgPath+'/testimonial-stars.svg" alt="Estrelas" class="testimony__stars">\
											<p class="testimony__block">\
												<span class="ps-ico ps-ico-quote1"></span>\
												Durante as minhas f&eacute;rias contratei o Seguro Viagem da Porto e ainda aproveitei a parceria com a Rent a Bag para alugar as minhas malas pro per&iacute;odo da viagem. O Plano Aventura tamb&eacute;m foi ideal para <strong>fazer os passeios com seguran&ccedil;a e mais tranquilidade por saber que estava protegida com o seguro</strong>.\
												\
											</p>\
										</div>\
										<div class="ps-carousel-item">\
											<img src="'+defaultParam.imgPath+'/testimonial-saulo-barros.webp" alt="Fotografia Saulo Barros" class="testimony__img">\
											<strong class="testimony__name">Saulo Barros</strong>\
											<span class="testimony__description">cliente Seguro Viagem da Porto Seguro</span>\
											<img src="'+defaultParam.imgPath+'/testimonial-stars.svg" alt="Estrelas" class="testimony__stars">\
											<p class="testimony__block">\
												<span class="ps-ico ps-ico-quote1"></span>\
												Precisei usar o seguro em 2021 durante uma viagem para os EUA onde tive COVID. Fui atendido no sistema de sa&uacute;de, e como os sintomas foram leves, tive que ficar isolado em um hotel e <strong>a Porto cobriu tudo, sem dor de cabe&ccedil;a</strong>. Recomendo muito!!\
												\
											</p>\
										</div>\
									</div>\
								</div>\
							</div>\
						</div>\
					</div>\
				</div>\
				\
				<div class="vd-lp-block vd-lp-block-faq">\
					<div class="ps-container">\
						<div class="ps-row">\
							<div class="ps-mod8 ps-sm-mod10 ps-sm-lspan1">\
								<h2 class="vd-faq-title">'+vdViagem.FillContent(vdViagem.DefaultID.lpSeoh3FaqTitle)+'</h2>\
								<ul class="ps-accordion">\
									<li class="ps-panel">\
										<a href="javascript:;" class="vd-faq-question ps-panel-head"><h3>'+vdViagem.FillContent(vdViagem.DefaultID.lpSeoh4FaqTitle0)+'</h3></a>\
										<div class="vd-faq-content ps-panel-ctt" style="display: none;">\
											<p>'+vdViagem.FillContent(vdViagem.DefaultID.lpSeoh4FaqDesc0)+'</p>\
										</div>\
									</li>\
									<li class="ps-panel">\
										<a href="javascript:;" class="vd-faq-question ps-panel-head"><h3>'+vdViagem.FillContent(vdViagem.DefaultID.lpSeoh4FaqTitle1)+'</h3></a>\
										<div class="vd-faq-content ps-panel-ctt" style="display: none;">\
											<p>'+vdViagem.FillContent(vdViagem.DefaultID.lpSeoh4FaqDesc1)+'</p>\
										</div>\
									</li>\
									<li class="ps-panel">\
										<a href="javascript:;" class="vd-faq-question ps-panel-head"><h3>'+vdViagem.FillContent(vdViagem.DefaultID.lpSeoh4FaqTitle2)+'</h3></a>\
										<div class="vd-faq-content ps-panel-ctt" style="display: none;">\
											<p>'+vdViagem.FillContent(vdViagem.DefaultID.lpSeoh4FaqDesc2)+'</p>\
										</div>\
									</li>\
									<li class="ps-panel">\
										<a href="javascript:;" class="vd-faq-question ps-panel-head"><h3>'+vdViagem.FillContent(vdViagem.DefaultID.lpSeoh4FaqTitle3)+'</h3></a>\
										<div class="vd-faq-content ps-panel-ctt" style="display: none;">\
											<p>'+vdViagem.FillContent(vdViagem.DefaultID.lpSeoh4FaqDesc3)+'</p>\
										</div>\
									</li>\
									<li class="ps-panel">\
										<a href="javascript:;" class="vd-faq-question ps-panel-head"><h3>'+vdViagem.FillContent(vdViagem.DefaultID.lpSeoh4FaqTitle4)+'</h3></a>\
										<div class="vd-faq-content ps-panel-ctt" style="display: none;">\
											<p>'+vdViagem.FillContent(vdViagem.DefaultID.lpSeoh4FaqDesc4)+'</p>\
										</div>\
									</li>\
								</ul>\
							</div>\
						</div>\
					</div>\
				</div>\
				\
				<div class="ps-container ps-footer-notes ps-pad-top--lg ps-pad-bottom--lg">\
					<div class="ps-row">\
						<div class="ps-mod8 ps-md-mod10 ps-md-lspan1">\
							<p>'+vdViagem.FillContent(vdViagem.DefaultID.footerLegalText0)+'</p>\
							<p>'+vdViagem.FillContent(vdViagem.DefaultID.footerLegalText1)+'</p>\
							<p class="'+(vdViagem.FillContent(vdViagem.DefaultID.lpFooterLegalText0) != '' ? '' : 'ps-hide')+'">'+vdViagem.FillContent(vdViagem.DefaultID.lpFooterLegalText0)+'</p>\
							<p class="'+(vdViagem.FillContent(vdViagem.DefaultID.lpFooterLegalText1) != '' ? '' : 'ps-hide')+'">'+vdViagem.FillContent(vdViagem.DefaultID.lpFooterLegalText1)+'</p>\
							<p class="'+(vdViagem.FillContent(vdViagem.DefaultID.lpFooterLegalText2) != '' ? '' : 'ps-hide')+'">'+vdViagem.FillContent(vdViagem.DefaultID.lpFooterLegalText2)+'</p>\
							<p class="'+(vdViagem.FillContent(vdViagem.DefaultID.lpFooterLegalText3) != '' ? '' : 'ps-hide')+'">'+vdViagem.FillContent(vdViagem.DefaultID.lpFooterLegalText3)+'</p>\
							<p><strong>'+vdViagem.FillContent(vdViagem.DefaultID.footerProcon)+'</strong></p>\
						</div>\
					</div>\
				</div>\
			</div>\
		</div>'; //parallax-body


	rtn += vdViagem.TplBuildModal("ModalDetalhePlano");
	rtn += vdViagem.TplBuildModal("ModalPlanoEuropa");

	rtn += vdViagem.TplBuildFooter(defaultParam.imgPath);

	rtn += '<div class="vd-modal-loading" style="display: none;" id="vd-modal-loading"></div>';

	rtn += '</div>'; // skin-container
	
	if(defaultParam.codigoAtendimento != undefined && defaultParam.calculo.isObjetoClonado != true) {
		vdViagem.PrefillHome(param);
	}
		
	return rtn;
};
vdViagem.TplBuildStateBlock = function(states,toDisplay) {
	var rtn = "";
	for(var i=0,l=toDisplay.length;i<l;i++) {
		var uf = toDisplay[i];
		if(toDisplay[i].indexOf(":") > -1) {
			toDisplay[i] = toDisplay[i].split(":");
			uf = toDisplay[i][0];
		}
		var idx = $.inArray(uf,states.val);
		if(idx > -1) {
			rtn += '<a href="javascript:;" class="vd-selItem vd-gotoStep2 vd-scrollTo '+(typeof toDisplay[i] == "object" ? (toDisplay[i][1] == "hide" ? "vd-sm-hide" : "vd-hide") : "")+'" data-scrollto="#vd-parallax-top" data-clonefield="#fl-entry-origem" data-showhide="show::#vd-calc-step2;hide::#vd-calc-step1" data-setvalue=".viagem-UfOrigem::'+states.val[idx]+';.viagem-DescUfOrigem::'+states.txt[idx]+'" value="'+states.val[idx]+'" data-value="'+states.val[idx]+'" data-group="origem">';
			rtn += '	<strong>'+states.val[idx]+'</strong> <span>'+states.txt[idx]+'</span>';
			rtn += '</a> ';
		}
	}

	return rtn;
};
vdViagem.TplBuildGetStates = function(options) {
	var rtn = {
			txt: [],
			val: []
		};

	options.each(function() {
		if($(this).val() != "") {
			rtn.txt.push($(this).text());
			rtn.val.push($(this).val());
		}
	});

	return rtn;
};
vdViagem.TplBuildSetPositionHash = function(pos) {
	var e = "",
		gtm = "";

	switch(pos) {
		case 1:
			e = "1:AlterarOrigem";
			break;
		case 2:
			e = "2:SelecaoDestino";
			break;
		case 3:
			e = "3:SelecaoPeriodo";
			break;
		case 4:
			e = "4:Passageiros";
			break;
		case 5:
			e = "5:MotivoViagem";
			break;
		case 6:
			e = "AlterarDados";
			break;
	}
	window.location.hash = e;
};
vdViagem.TplBuildAction = function(post) {
	var frm = $("form#viagem-calculoViagemForm");

	frm.attr("action",post);
};


window.count = 0;
vdViagem.GetCountriesList = function(param) {
	var getListCountries = [];
	
	$("#fl-entry-destino option").each(function(){
		var value = $(this).val(),
			value = value.split("|");

		getListCountries.push({
			codigoPaisDestino: value[0],
			codigoContinenteDestino: value[1],
			name: value[2],
			nameNoChar: latinize(value[2])
		});
	});
	
	vdViagem.BuildGetCountriesList(param,getListCountries);
};
vdViagem.BuildGetCountriesList = function(param,countries) {
	var defaultCountries = {};
	
	defaultCountries.countries = countries;

	$.extend(param,defaultCountries);

	$(".viagem-select-multidestinos").flexdatalist({
		minLength: 1,
		textProperty: '{name}',
		valueProperty: ["codigoPaisDestino","codigoContinenteDestino","name"],
		selectionRequired: true,
		visibleProperties: 'name',
		searchIn: ['name','nameNoChar'],
		noResultsText: 'N&atilde;o h&aacute; resultados para o destino "{keyword}"',
		data: countries,
		normalizeString: function (string) {
			return latinize(string);
		}
	}).on("change:flexdatalist",function (obj,v,t,o) {
		vdViagem.SetCountriesSelected(obj,v,t,o);
    });
};
vdViagem.SetCountriesSelected = function(obj,v,t,o){
	v = JSON.parse(v);

    var selected = {},
    	val = "";
    for(var i=0,l=v.length;i<l;i++) {
    	if(v[i].name == t) {
    		selected = v[i];
    		break;
    	}
    }

    val = selected.codigoPaisDestino+"|"+selected.codigoContinenteDestino+"|"+selected.name;
    window.count++;

    vdViagem.UpdateCountriesSelected($(".viagem-select-multidestinos.flexdatalist-selected"),window.count);
    $(".flexdatalist-multiple li.value").last().attr({
    	"idx":count,
    	"val":val
    });

	if(t == "Brasil" && $(".flexdatalist-multiple li").length == 2)
		$("#fl-entry-destino").val("28|4|Brasil").trigger("change");
};
vdViagem.UpdateCountriesSelected = function(id,count) {
	var t = $(id),
		obj = JSON.parse(t.val()),
		objMoreDestiny = obj.slice(-1)[0];	

	if(window.count <= 1) {
		var strMainDestination = obj[0].codigoPaisDestino+"|"+obj[0].codigoContinenteDestino+"|"+obj[0].name;

		$("select[name='destino-pais']").val(strMainDestination).trigger("change");
	
	} else {
	 	var strOthersDestinations = objMoreDestiny.codigoPaisDestino+"|"+objMoreDestiny.codigoContinenteDestino+"|"+objMoreDestiny.name;

	 	frmLib.CloneForm("#destinos;#cttFrm",1,"voViagem.SetCloneQuantity(\"#cttFrm\",\"input[name='qtdeDestino']\")");
	 	var ctt = "#"+$("#cttFrm .fl-clone").last().attr("id");
		
		window.setTimeout(function(){
			$("select",ctt).val(strOthersDestinations).trigger("change");
		},250);
	}

	vdViagem.BindingCountrieRemoved();

	$("#vd-step2-submitRow").fadeIn();
};
vdViagem.BindingCountrieRemoved = function() {
	$(".flexdatalist-multiple li.value .fdl-remove").off("click.flex").on("click.flex",function(){
		var t = $(this),
			idx = t.parent().attr("idx"),
			val = $(".flexdatalist-multiple li.value:eq(0)").attr("val");
		
		if(idx == 1 && window.count > 1) {
			window.setTimeout(function(){$("select[name='destino-pais']").val(val).trigger("change");},100);
			frmLib.CloneRemove("#cttFrm;#destino_2","voViagem.SetCloneQuantity(\"#cttFrm\",\"input[name='qtdeDestino']\")");
		} else {
			frmLib.CloneRemove("#cttFrm;#destino_"+idx,"voViagem.SetCloneQuantity(\"#cttFrm\",\"input[name='qtdeDestino']\")");
		}
		
		vdViagem.UpdateCountriesRemoved();
	});
}
vdViagem.UpdateCountriesRemoved = function() {
	var list = $(".flexdatalist-multiple li.value"),
		idx = 1;

	list.each(function(){
		var newId = idx++;
		$(this).attr("idx",newId);
	});

	window.count--;

	if(window.count == 1) {
		frmLib.CloneRemove("#cttFrm;#destino_2","voViagem.SetCloneQuantity(\"#cttFrm\",\"input[name='qtdeDestino']\")");
		vdViagem.UpdateCountriesSelected(".viagem-select-multidestinos",window.count);
	} else if(window.count == 0) {
		$("select[name='destino-pais']").val("").trigger("change");
		$(".viagem-paisEuropeu, #vd-step2-submitRow").hide();
	}
}

// Bindings
vdViagem.HomeInit = function(container) {
	vdViagem.Init(container);
	vdViagem.HomeBindings(container);
	vdViagem.GetCountriesList(window.dataObject);

	vdViagem.HomeSlides();
	vdViagem.HomeFaq();
};

// Ações
vdViagem.HomeBindings = function(container) {
	$(".vd-selItem").on("click",function() {
		var t = $(this),
			g = t.data("group");

		if(typeof g != "undefined" && g != "")
			$(".vd-selItem[data-group='"+g+"']").removeClass("selected");

		vdViagem.SetParallaxOff();

		t.addClass("selected");
	});

	$(".vd-calcBack").on("click",function() {
		var to = $(this).data("to"),
			step2 = $("#vd-calc-step2"),
			step3 = $("#vd-calc-step3"),
			step4 = $("#vd-calc-step4"),
			step5 = $("#vd-calc-step5"),
			y = window.scrollY > 0 ? window.scrollY : window.pageYOffset;

		if($("#vd-topCalcStartup").is(":visible") && $(".vd-novoCalculoDo").is(":visible")) {
			if(step2.is(":visible") && y >= (step2.offset().top-20))
				to = 0;
			// if(step5.is(":visible") && y >= (step5.offset().top-20))
			// 	to = 3;
			// else if(step4.is(":visible") && y >= (step4.offset().top-20))
			// 	to = 2;
			// else if(step3.is(":visible") && y >= (step3.offset().top-20))
			// 	to = 1;
			//else
				//to = 0;
		}

		vdViagem.GotoStep(to);
	});

	$(window).on("scroll",function() {
		var session = $(".vd-novoCalculoDo").data("session");
		if(session) {
			var stepPos = $(".vd-novoCalculoDo").data();
			var y = window.scrollY > 0 ? window.scrollY : window.pageYOffset;

			if(typeof stepPos.step5anchor != "undefined" && y >= (stepPos.step5anchor.top-20))
				vdViagem.TplBuildSetPositionHash(5);
			else if(typeof stepPos.step4anchor != "undefined" && y >= (stepPos.step4anchor.top-20))
				vdViagem.TplBuildSetPositionHash(4);
			else if(typeof stepPos.step3anchor != "undefined" && y >= (stepPos.step3anchor.top-20))
				vdViagem.TplBuildSetPositionHash(3);
			else 
				vdViagem.TplBuildSetPositionHash(2);
		}
	}).on("keydown",function(e) {
		if(e.keyCode == 27)
			$(".vd-modal:visible .vd-modalClose").trigger("click");
	});

	
	$(".vd-add, .vd-sub",container).on("click.fb",function() {
		var t = $(this),
			p = t.parent().parent().children("input"),
			min = typeof p.data("min") !== "undefined" ? parseInt(p.data("min")) : 0,
			limit = p.data("limit"),
			error = $("#"+p.next().attr("id")),
			totalPax = typeof $(".vd-novoCalculoDo").data("totalPax") != "undefined" ?  parseInt($(".vd-novoCalculoDo").data("totalPax")) : 0,
			v = parseInt(t.parent().find("strong").text()),
			nv = v;
		
		if(t.is(".vd-add")) {
			v++;

			if(totalPax < limit) {
				nv = v;
				totalPax++;

				if(nv > min) {
					t.parent().find(".vd-sub").removeClass("disabled");
				}

				if(p.is(".vd-qtdPassageiros70") && nv == (limit-1)) {
					t.addClass("disabled");
				} else {
					if(totalPax == limit) {
						$(".vd-add",container).addClass("disabled");
					} else {
						$(".vd-add",container).removeClass("disabled");
					}
				}
			} else {
				$("#vd-qtdeLimite span:eq(1)").text(limit);
				$("#vd-qtdeLimite").fadeIn("fast");
				//error.show();
			}
		} else {
			v--;
			$("#vd-qtdeLimite span:eq(1)").text("");
			$("#vd-qtdeLimite").fadeOut("fast");
			//error.hide();
			if(v >= 0) {
				nv = v;
				totalPax--;

				$(".vd-add",container).each(function() {
					var ipt = $(this).parents(".vdo_startup__form_passenger_item").find("input");
					if(!(ipt.is(".vd-qtdPassageiros70") && ipt.val() == (limit - 1))) {
						$(this).removeClass("disabled");
					} 
				});
				if(totalPax == 0) {
					$(".vd-sub",container).addClass("disabled");
				} 
				if(v == min) {
					$(this).addClass("disabled");
				}
			} 
		}
		
		if(totalPax <= 0)
			$("#vd-calc-step4 .vd-submitRow").fadeOut("fast");
		else {
			if(!$("#vd-calc-step5").is(":visible"))
				$("#vd-calc-step4 .vd-submitRow").fadeIn("fast");
		}

		if(totalPax > 1) {
			$("#vd-alertaApolice").slideDown("fast");
		} else {
			$("#vd-alertaApolice").slideUp("fast");
		}
		
		$(".vd-novoCalculoDo").data("totalPax",totalPax);
		$("#vd-top-step3-pessoas").html(totalPax)
		
		t.parent().find("strong").html(nv);
		$(p).val(nv).trigger("change");

		if(!psLib.IsMobile || !psLib.IsTablet)
			$("#vd-top-step3").fadeIn("fast");

		var ctt = "",
			menor = $(".vd-novoCalculoDo .vd-qtd-menor").val(),
			maior = $(".vd-novoCalculoDo .vd-qtd-maior").val();

		if(parseInt(menor) > 0) {
			ctt += "At&eacute; 70: "+menor;
		}
		if(parseInt(maior) > 0) {
			if(ctt != "") ctt += "<br/>";
			ctt += "Mais de 70: "+maior;
		}
		$("#vd-top-step3-idades").html(ctt);
		$(".vdo_startup__form_passenger--total").val(totalPax+" passageiro"+(totalPax > 1 ? 's' : '')).trigger("change");
		//$(".vdo_startup__form_passenger").hide();
	});
	$(".vd-add:eq(0)").trigger("click");

	// $(".vd-gotoStep2").on("click",vdViagem.CalcStartup);
	// $(".vd-gotoStep3").on("click",vdViagem.Step3Startup);
	// $(".vd-gotoStep4").on("click",vdViagem.Step4Startup);
	//$(".vd-gotoStep5").on("click",vdViagem.Step5Startup);
	// $(".vd-gotoStep4").on("click",function() {
	// 	frmLib.FormSubmit();
	// });

	/*$(".vd-add-destiny").off("click.adddy").on("click.adddy",function(){
		frmLib.CloneForm("#destinos;#cttFrm",1,"voViagem.SetCloneQuantity(\"#cttFrm\",\"input[name='qtdeDestino']\")");
		frmLib.CloneForm("#viagem-destinos;#viagem-cttDestinos");

		var ctt = "#"+$("#viagem-cttDestinos .fl-clone").last().attr("id");

		vdInterface.CloneConfigurations(ctt);
		vdViagem.HomeBindings(ctt);
	});

	$(".vd-remove-destiny").off("click.removedy").on("click.removedy",function() {
		var t = $(this),
			idx = t.data("idx");

		frmLib.CloneRemove("#cttFrm;#destino_"+idx,"voViagem.SetCloneQuantity(\"#cttFrm\",\"input[name='qtdeDestino']\")");
		frmLib.CloneRemove("#viagem-cttDestinos;#viagem-destino_"+idx,"voViagem.UpdateCountryList(\"#viagem-destino_"+idx+"\",true)");
	});*/

	$(".flexdatalist-multiple li").off("click.removedy").on("click.removedy",function() {
		var t = $(this),
			idx = t.data("idx");

		frmLib.CloneRemove("#cttFrm;#destino_"+idx,"voViagem.SetCloneQuantity(\"#cttFrm\",\"input[name='qtdeDestino']\")");
	});

	$("#vd-calc-step1-select .vd-btn").on("click",function() {
		vdViagem.SetParallaxOff();
		$(".vd-novoCalculoDo").data("session",true);
	});

	$(".vd-lp-faq li h3").on("click",function() {
		var p = $(this).parent();
		if(p.is(".opened"))
			p.removeClass("opened");
		else
			p.addClass("opened");
	});

	//Origem: Brasil
	$(".viagem-UfOrigem").val("SP").trigger("change");

	vdViagem.Step2InternationalCountriesSelect();

	let date = new Date(),
		cssPath = typeof window.defaultParam.jsPath !== "undefined" ? window.defaultParam.jsPath : 'https://vdo.campanhaporto.com.br/vdo/_production/nova-arquitetura/js/'; 

	const picker = new easepick.create({
        element: document.querySelector('.vdo_startup__form--calendar'),
		lang: 'pt-BR',
		format: 'DD-MM-YYYY',
		zIndex: 5000,
        css: [
			cssPath + 'plugins/easepick.css',
        ],
		plugins: ['RangePlugin','LockPlugin'],
		RangePlugin: {
			tooltipNumber(num) {
				return num - 1;
			},
			locale: {
				one: 'noites',
				other: 'noites',
			},
		},
		LockPlugin: {
			minDate: date.setDate(date.getDate()),
			// maxDate: date.setFullYear(date.getFullYear() + 1), 
		},
		setup(picker) {
			picker.on('view', (e) => {
				const { view, date, target } = e.detail;

				if(view == "CalendarHeader") {
					target.querySelector(".previous-button").setAttribute("title","Anterior");
					target.querySelector(".next-button").setAttribute("title","Proximo");
				}
				
			});
			picker.on('select', (e) => {
				const { view, date, target } = e.detail;
				let dStart = e.detail.start.format('DD/MM/YYYY'),
					dEnd = e.detail.end.format('DD/MM/YYYY');

				$(".vdo_startup__form--calendar").trigger("change");
				$("input[name='calculo.dataSaidaProponente']").val(dStart).trigger("change");
				$("input[name='calculo.dataRetornoProponente']").val(dEnd).trigger("change");

				$("#vd-top-step3-datas").html(dStart+" at&eacute; "+dEnd);
				$("#vd-top-step2").fadeIn("fast");
			});
		}
    });
	
	psLib.Init(".vd-lp-block-testimony"); 
	setTimeout(function() {
		$("button.ui-datepicker-current").attr("title","m&ecirc;s atual");
		$("button.ui-datepicker-close").attr("title","fechar");

		$("#testimony__carousel").each(function() {
			carouselStartup(this);
		}).on("init afterChange",function(slick) {
			carouselStartup(this);
		});
	},200);

	function carouselStartup(t) {
		let previousArrow = $(".ps-carousel-left",t),
			nextArrow = $(".ps-carousel-right",t),
			activeCarousel = $(".slick-active",t);

		previousArrow.attr("title","Depoimento anterior");
		nextArrow.attr("title","Próximo depoimento");

		if($("img",previousArrow).length == 0) {
			previousArrow.html("<img class='testimony__img' alt='' src='data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'>");
		}
		if($("img",nextArrow).length == 0) {
			nextArrow.html("<img  class='testimony__img' alt='' src='data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'>");
		}
		
		carouselThumb(activeCarousel,previousArrow,nextArrow);
	}

	function carouselThumb(activeSlide,arrowLeft,arrowRight) {
		let previousCarousel = activeSlide.prev(),
			nextCarousel = activeSlide.next();
		
		$("img",arrowLeft).attr("src",$(".testimony__img",previousCarousel).attr("src"));
		$("img",arrowLeft).attr("alt",$(".testimony__img",previousCarousel).attr("alt"));
		$("img",arrowRight).attr("src",$(".testimony__img",nextCarousel).attr("src"));
		$("img",arrowRight).attr("alt",$(".testimony__img",nextCarousel).attr("alt"));
	}
};
vdViagem.SetParallaxOff = function() {
	$("body").data("parallax",true);
	$("#vd-parallax-top").height("auto");
};
vdViagem.GotoStep = function(to) {
	if(to == 0) {
		var step2 = $("#vd-calc-step2");

		step2.hide();

		if(psLib.IsMobile || psLib.IsTablet) {
			$(".vd-stepFeedback").addClass("vd-md-hide").removeAttr("style");
			$("#vd-top-step1, #vd-top-step2").fadeIn("fast");
		}

		if(typeof window.dataLayer !== "undefined") {
			window.dataLayer.push({
				'event': 'step_change_home',
				'step': 'origem'
			});
		}
		vdViagem.TplBuildSetPositionHash(1);
	}
	// } else if(to == 1) {
	// 	$(".vd-calcBack").hide();
	// 	$("#vd-calc-step2 .vd-submitRow").fadeIn();
		
	// 	if(psLib.IsMobile || psLib.IsTablet) {
	// 		$(".vd-stepFeedback").addClass("vd-md-hide").removeAttr("style");
	// 		$("#vd-top-step1").fadeIn("fast");
	// 	}
	// 	$("html,body").animate({
	// 		scrollTop: 0
	// 	},300);

	// 	if(typeof window.dataLayer !== "undefined") {
	// 		window.dataLayer.push({
	// 			'event': 'step_change_home',
	// 			'step': 'SelecaoDestino'
	// 		});
	// 	}
	// } else if(to == 2) {
	// 	var step3 = $("#vd-calc-step3").offset();
	// 	if(psLib.IsMobile || psLib.IsTablet) {
	// 		$(".vd-stepFeedback").addClass("vd-md-hide").removeAttr("style");
	// 		$("#vd-top-step2").fadeIn("fast");
	// 	}
	// 	$("html,body").animate({
	// 		scrollTop: step3.top
	// 	},300);

	// 	if(typeof window.dataLayer !== "undefined") {
	// 		window.dataLayer.push({
	// 			'event': 'step_change_home',
	// 			'step': 'SelecaoPeriodo'
	// 		});
	// 	}

	// 	vdViagem.TplBuildSetPositionHash(3);
	// } else if(to == 3) {
	// 	var step4 = $("#vd-calc-step4").offset();
	// 	if(psLib.IsMobile || psLib.IsTablet) {
	// 		$(".vd-stepFeedback").addClass("vd-md-hide").removeAttr("style");
	// 		$("#vd-top-step3").fadeIn("fast");
	// 	}
	// 	$("html,body").animate({
	// 		scrollTop: step4.top
	// 	},300);
	// 	if(typeof window.dataLayer !== "undefined") {
	// 		window.dataLayer.push({
	// 			'event': 'step_change_home',
	// 			'step': 'Passageiros'
	// 		});
	// 	}
	// } else if(to == 4) {
	// 	var frm = $(".vd-novoCalculoDo"),
	// 		result = $(".vd-messageScreen, #vd-resultadoCalculo");

	// 	result.hide();
	// 	frm.fadeIn("fast",function() {
	// 		var step5 = $("#vd-calc-step5").offset();
	// 		$(".vd-stepFeedback").removeClass("vd-noPointer");
	// 		if(psLib.IsMobile || psLib.IsTablet) {
	// 			$(".vd-stepFeedback").addClass("vd-md-hide").removeAttr("style");
	// 			$("#vd-top-step5").hide();
	// 			$("#vd-topCalcStartup").show();
	// 			if(psLib.IsTablet) {
	// 				$(".vd-md-sessionHide").show();
	// 			}
	// 			$("#vd-top-step4").fadeIn("fast");

	// 		}
	// 		$("html,body").animate({
	// 			scrollTop: step5.top
	// 		},300);
	// 	});

	// 	$(".vd-novoCalculoDo").data("session",true);
	// 	vdViagem.TplBuildSetPositionHash(6);
	// 	if(typeof window.dataLayer !== "undefined") {
	// 		window.dataLayer.push({
	// 			'event': 'step_change_home',
	// 			'step': 'MotivoViagem'
	// 		});
	// 	}
	// }
};
vdViagem.Step2Startup = function(prefill) {
	if(typeof prefill != "boolean") prefill = false;

	var step2 = $("#vd-calc-step2"),
		topStartup = $("#vd-topCalcStartup"),
		toHide = $("#vd-topDefault, #vd-parallax-body"),
		uf = "Brasil",
		codCountry = $("input[name='calculo.codigoPaisDestino']").val(),
		country = $("select[name='destino-pais'] option:selected").text(),
		countries = $(".frmVdo .viagem-select-destinos[value!='']"),
		countriesQtt = countries.length,
		topUf = $("#vd-top-step1-origem"),
		topCountryInfo = $("#vd-top-step1-destino"),
		winH = window.innerHeight+250;

	toHide.hide();

	$("#vd-parallax-top").addClass("vd-session");

	if(psLib.IsDesktop) {
		topStartup.fadeIn("fast");
	}

	if(countriesQtt > 0 && codCountry != "28") {
		if(countriesQtt > 1)
			country = country + " (+"+(countriesQtt-1)+")";
	}

	topUf.text(uf);
	topCountryInfo.text(country);

	topStartup.find(".vd-calcBack").data("to",2).show();
	vdViagem.TplBuildSetPositionHash(2);

	$(".vd-session .ps-logo").removeClass("ps-logo-white");

	if(!psLib.IsMobile)
		step2.css("min-height",winH+"px");
	else
		step2.css("min-height",window.innerHeight+100+"px");

	if(prefill) {

	} else {
		step2.fadeIn("fast",function() {
			var p = $(this).offset(),
				n = $(".ps-notify").css("margin-bottom") == "0px" ? $(".ps-notify").innerHeight() : 0;
			$("html,body").animate({
				scrollTop: p.top - n
			},500);
		});	
	}
}
vdViagem.Step3Startup = function(prefill) {
	if(typeof prefill != "boolean") prefill = false;

	var step2 = $("#vd-calc-step2"),
		step3 = $("#vd-calc-step3"),
		topStartup = $("#vd-topCalcStartup"),
		toHide = $("#vd-topDefault, #vd-parallax-body"),
		uf = "Brasil",
		codCountry = $("input[name='calculo.codigoPaisDestino']").val(),
		country = $("select[name='destino-pais'] option:selected").text(),
		countries = $(".frmVdo .viagem-select-destinos[value!='']"),
		countriesQtt = countries.length,
		topUf = $("#vd-top-step1-origem"),
		topCountryInfo = $("#vd-top-step1-destino"),
		winH = window.innerHeight+150,
		isReturning = step3.is(":visible");

	toHide.hide();

	if(country == "Brasil")
		$(".viagem-btn-destino").stop(true,true).hide();

	$(".ps-notify-close").trigger("click");
	$("#vd-calc-step2 .vd-submitRow").hide();

	$("#vd-parallax-top").addClass("vd-session");
	topStartup.fadeIn("fast");

	if(countriesQtt > 0 && codCountry != "28") {
		if(countriesQtt > 1)
			country = country + " (+"+(countriesQtt-1)+")";
	} else {
		vdViagem.ClearPais();
	}

	topUf.text(uf);
	topCountryInfo.text(country);

	topStartup.find(".vd-calcBack").data("to",1).show();
	vdViagem.TplBuildSetPositionHash(3);

	var configDate = {
		min: step3.find(".viagem-cal-ida").data("calendarmindate"),
		max: step3.find(".viagem-cal-ida").data("calendarmaxdate"),
		month: ['Janeiro','Fevereiro','Mar&ccedil;o','Abril','Maio','Junho',
        'Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'],
        dayNamesShort: ['Dom','Seg','Ter','Qua','Qui','Sex','S&aacute;b'],
		dayNamesMin: ['Dom','Seg','Ter','Qua','Qui','Sex','S&aacute;b'],
	};

	$(".viagem-cal-ida").datepicker("destroy").datepicker({
		minDate: configDate.min,
		maxDate: configDate.max,
		monthNames: configDate.month,
		dayNamesShort: configDate.dayNamesShort,
		dayNamesMin: configDate.dayNamesMin,
		//changeMonth: true,
		//changeYear: true,
		numberOfMonths: psLib.IsMobile ? [1,1] : [1,2],
		// beforeShowDay: vdViagem.SetDatesHighlight,
		onSelect: function(s,o) {
			//step3.find(".vd-step3-sd-depart").removeClass("vd-step3-sd-active");
			step3.find(".vd-step3-sd-depart div span:eq(1)").html($("#vd-cal-ida").val());
			step3.find(".vd-step3-sd-return").trigger("click");

			eval('window.voViagem.SetLimitTravelBack(s,o)');

			var dateA = s,
				dateStringA = new Date(o.selectedYear,o.selectedMonth,o.selectedDay),
				dateStringB = new Date(o.selectedYear,o.selectedMonth,o.selectedDay),
				dateA = $.datepicker.formatDate("dd/mm/y",dateStringA),
				dateB = $.datepicker.formatDate("dd/mm/y",dateStringB),
				diff = Math.floor(Math.abs(dateStringB.getTime() - dateStringA.getTime()) / (1000 * 3600 * 24))+1;

			//$("#vd-top-step2-periodo").html("2 <small>dias</small>");
			$("#vd-top-step2-periodo").html((diff)+" <small>dia"+(diff > 1 ? "s" : "")+"</small>");

			if(psLib.IsMobile || psLib.IsTablet)
				$("#vd-top-step1").addClass("vd-md-hide").removeAttr("style");


			$("#vd-top-step1").addClass("vd-md-hide");
			$("#vd-top-step2").fadeIn(200,function() {
				if(!prefill) {
					if(psLib.IsMobile) {
						var p = $("#vd-cal-volta-ctt").offset();
						$("html,body").animate({
							scrollTop: (p.top-250)
						},500);
					}
				}
			});

			if(!prefill) {
				step3.find(".vd-submitRow").fadeIn("fast");
				//step3.find("h2 strong").html("Volta");
			}

			$(".viagem-cal-ida").datepicker("option","beforeShowDay",vdViagem.SetDatesHighlight);

			// if(!psLib.IsMobile) {
			// 	step3.find("h2 strong").html("Volta");
			// 	step3.find(".vd-step3-title small").html("Selecione a data de chegada ao Brasil");
			// 	step3.find(".vd-step3-title").removeClass("vd-sm-mod12").addClass("vd-mod6").addClass("vd-lspan6");
			// }

			configDate.min = new Date(o.selectedYear,o.selectedMonth,o.selectedDay);
			configDate.max = o.settings.maxDate;

			$(".viagem-cal-volta").datepicker("destroy").datepicker({
				minDate: configDate.min,
				maxDate: configDate.max,
				monthNames: configDate.month,
				dayNamesShort: configDate.dayNamesShort,
				dayNamesMin: configDate.dayNamesMin,
				//changeMonth: true,
				//changeYear: true,
				numberOfMonths: psLib.IsMobile ? [1,1] : [1,2],
				beforeShowDay: vdViagem.SetDatesHighlight,
				onSelect: function(sb,ob) {
					$(".vd-step3-sd-return div span:eq(1)").html($("#vd-cal-volta").val());

					eval('window.voViagem.SetTravelDuration(sb,ob)');
					dateB = sb,
					dateStringA = new Date(o.selectedYear,o.selectedMonth,o.selectedDay),
					dateStringB = new Date(ob.selectedYear,ob.selectedMonth,ob.selectedDay),
					dateB = $.datepicker.formatDate("dd/mm/y",dateStringB),
					diffDays = Math.floor(Math.abs(dateStringB.getTime() - dateStringA.getTime())),
					diffHourDay = 1000 * 3600 * 24,
					diff = Math.round(diffDays / diffHourDay)+1;

					$("#vd-top-step2-periodo").html((diff)+" <small>dia"+(diff > 1 ? "s" : "")+"</small>");
					$("#vd-top-step3-datas").html(dateA+" at&eacute; "+dateB);

					if(diff <= 1)
						$("#viagem-txtQuantidadeDias").slideDown();
					else
						$("#viagem-txtQuantidadeDias").slideUp();


					$("#vd-cal-ida").datepicker("setDate",s).trigger("change");
					$("#vd-cal-volta").datepicker("setDate",sb).trigger("change");

					if(psLib.IsMobile) {
						var p = $("#vd-cal-volta-ctt").offset();

						setTimeout(function() {
							$("html,body").animate({ scrollTop: p.top}, 500);
						},200);
					}
				}
			});

			$("#vd-cal-volta-ctt").fadeIn("slow");
		}
	});

	$(".viagem-cal-ida .ui-datepicker-current-day a").removeClass("ui-state-hover");

	$("*[data-addremoveclass]").off("click.arc change.arc").on("click.arc change.arc",function(e) {
		var t = $(this),
			cmd = t.data("addremoveclass");

			cmd = frmLib.ProccessCmd(cmd);

			var v = t.is("select") && e.type == "change" ? t.val() : "";

			for(var i=0,l=cmd.length;i<l;i++) {
				var checkRule = frmLib.ApplyRulesCmd(cmd[i],v);

				if(checkRule)
					frmLib.FormAddRemoveClass(cmd[i].expression);
			}
	});

	if(!psLib.IsMobile)
		step3.css("min-height",winH+"px");

	if(prefill) {
		step3.show();
		step3.find(".vd-submitRow").hide();

		if(psLib.IsDesktop)
			step3.css("height","500px").css("min-height","auto");

		if(psLib.IsMobile || psLib.IsTablet) {
			$(".vd-stepFeedback").addClass("vd-md-hide").removeAttr("style");
		}

		if(psLib.IsMobile) {
			$("#vd-topCalcStartup").hide();
			$("#vd-top-step5").show();
		}
	} else {
		step3.fadeIn("fast",function() {
			var p = $(this).offset(),
				n = $(".ps-notify").css("margin-bottom") == "0px" ? $(".ps-notify").innerHeight() : 0;
			$("html,body").animate({
				scrollTop: p.top - n
			},500);
		});	
	}

	$(".vd-novoCalculoDo").data("step3anchor",step3.offset());

	if(typeof window.dataLayer !== "undefined") {
		window.dataLayer.push({
			'event': 'step_change_home',
			'step': 'SelecaoPeriodo'
		});
	}
	if(typeof window.customData !== "undefined") {
		if(psLib.IsMobile)
			window.customData.site.version = "v2-mobile";
		else if(psLib.IsTablet)
			window.customData.site.version = "v2-tablet";
		else
			window.customData.site.version = "v2-desktop";
	}
	//window.location.hash = "3:SelecaoPeriodo";
};
vdViagem.SetDatesHighlight = function(date) {
	var startDate = $(".viagem-cal-ida").val(),
		endDate = $(".viagem-cal-volta").val();

	startDate = startDate.split('/');
	endDate = endDate.split('/');
	startDate = new Date(startDate[2], (startDate[1]-1), startDate[0]);
	endDate = new Date(endDate[2], (endDate[1]-1), endDate[0]);

	if($.trim(startDate) != '' && $.trim(endDate) !='') {
		if(($.trim(startDate) == $.trim(date)) && ($.trim(endDate) == $.trim(date))) {
			return [true, 'dp-highlight dp-end-highlight dp-start-highlight'];
		}

		if($.trim(startDate) == $.trim(date)) {
			return [true, 'dp-highlight dp-start-highlight'];
		}

		if(date < endDate && date > startDate ) {
			return [true, 'dp-highlight'];
		}

		if($.trim(endDate) == $.trim(date)) {
			return [true, 'dp-highlight dp-end-highlight'];
		}

		return [true, ''];
	}
};
frmLib.FormShowHide = function(cmd) {
	var mode = cmd[0],
		selector = cmd[1];

	selector = selector.replace(/\{/g,"\\{").replace(/\}/g,"\\}");

	if(mode == "show")
		$(selector).show();
	else
		$(selector).hide();
};
vdViagem.Step4Startup = function(prefill) {
	if(typeof prefill != "boolean") prefill = false;

	var topStartup = $("#vd-top-step3"),
		step4 = $("#vd-calc-step4"),
		step3 = $("#vd-calc-step3"),
		winH = window.innerHeight;

	step3.css("height","auto");
	step4.find(".vd-submitRow").fadeIn();

	if(!psLib.IsDesktop && (psLib.IsMobile || psLib.IsTablet)) {
		$(".vd-stepFeedback").addClass("vd-md-hide").removeAttr("style");
	}
	$("#vd-top-step2").addClass("vd-md-hide");
	
	$("input[name='fake.isPraticaAventura']",step4).click(function() {
		if(this.value == "true") {
			$(".set-viagem-qtdPassageirosMaior").val("0").trigger("change");

			var ctt ="",
				menor = parseInt($(".vd-qtd-menor").val()),
				maior = parseInt($(".vd-qtd-maior").val());

			if((maior+menor) > 0) {
				ctt += "At&eacute; 70: "+menor;
				$("#vd-qtdeLimite").fadeOut("fast");
			}
			$("#vd-top-step3-pessoas").html(menor);
			$(".vd-novoCalculoDo").data("totalPax",menor);
			$("#vd-top-step3-idades").html(ctt);

			$("#vd-qtdPassagMaiorSetentaAnosBlock").fadeOut("fast",function() {
				$(this).find("strong").html("0");

				$("#vd-qtdPassagMenorSetentaAnosBlock").animate({
					marginLeft: (psLib.IsMobile ? "25%" : "38%")
				},300);
			});
		} else {
			$("#vd-qtdPassagMenorSetentaAnosBlock").animate({
				marginLeft: (psLib.IsMobile ? "0%" : "24.99%")
			},300,function() {
				$("#vd-qtdPassagMaiorSetentaAnosBlock").fadeIn("fast");		
			});
			
		}
	});

	topStartup.fadeIn("fast");

	if(prefill) {
		step4.show();
		step4.find(".vd-submitRow").hide();
		//$("#vd-top-step3-pessoas").html(parseInt($(".set-viagem-qtdPassageirosMenor").val()) + parseInt($(".set-viagem-qtdPassageirosMaior").val()));

		if(psLib.IsDesktop)
			step3.css("height","500px").css("min-height","auto");

		//remove passageiro principal pré-fixado no fluxo normal
		$("#vd-qtdPassagMenorSetentaAnos .vd-sub").trigger("click");

	} else {
		//adiciona um passageiro principal no fluxo normal
		$(".vd-qtd-menor").val("1").trigger("change");

		step4.css("min-height",winH+"px").fadeIn("fast",function() {
			var p = $(this).offset();
			$("html,body").animate({
				scrollTop: p.top
			},500);
		});
	}

	$(".vd-novoCalculoDo").data("step4anchor",step4.offset());

	if(typeof window.dataLayer !== "undefined") {
		window.dataLayer.push({
			'event': 'step_change_home',
			'step': 'Passageiros'
		});
	}
	//window.location.hash = "4:Passageiros";
};
vdViagem.Step5Startup = function(prefill) {
	if(typeof prefill != "boolean") prefill = false;

	var topStartup = $("#vd-top-step4"),
		step5 = $("#vd-calc-step5"),
		step4 = $("#vd-calc-step4"),
		winH = window.innerHeight;

	step4.css("height","auto");
	$("#vd-top-step3").removeAttr("style");
	topStartup.fadeIn("fast");

	if(prefill) {
		step5.show();
		step5.find(".vd-submitRow").hide();
		
		if(psLib.IsDesktop) {
			step4.css("height","600px").css("min-height","auto");
			step5.css("height","650px").css("min-height","auto");
		}

		if(!psLib.IsDesktop && (psLib.IsMobile || psLib.IsTablet)) {
			$(".vd-stepFeedback").removeAttr("style");
		}
	} else {
		step5.css("height",winH+"px").fadeIn("fast",function() {
			var p = $(this).offset();
			$("html,body").animate({
				scrollTop: p.top
			},500);
		});
	}

	step5.find("input[name='fake.motivoViagem']").on("click",function() {
		var t = $.trim($(this).next().text());
		$("#vd-top-step4-motivo").html(t);
		step5.find(".vd-submitRow").fadeIn();

		if(!psLib.IsDesktop && (psLib.IsMobile || psLib.IsTablet))
			$(".vd-stepFeedback").addClass("vd-md-hide").removeAttr("style");
		
		if(!prefill)
			topStartup.fadeIn("fast").removeClass("vd-md-hide");
	});

	$(".vd-novoCalculoDo").data("step5anchor",step5.offset());

	if(typeof window.dataLayer !== "undefined") {
		window.dataLayer.push({
			'event': 'step_change_home',
			'step': 'MotivoViagem'
		});
	}
	//window.location.hash = "5:MotivoViagem";
};
vdViagem.CalcStartup = function() {
	var step1 = $("#vd-calc-step1"),
		step1Tit = $("#vd-step1-tit"),	
		step2 = $("#vd-calc-step2"),
		uf = $(".viagem-UfOrigem option:selected").text();


	$(".ps-notify-close").trigger("click");
	$("#vd-top-step1-origem").html(uf);
	step1Tit.find("h2 span.vd-uf").html(uf.find("span").text());
	step1Tit.find("h2 span.vd-icon").removeClass("vd-pin1-md-gray").addClass("vd-pin2-md-gray").html("A");
	step1Tit.find(".vd-calcBack").data("to",0).css("margin-left","50px").fadeIn("fast",function() {
		$(this).animate({
			marginLeft: 0
		},100);
	});
	step1.hide();
	step2.slideDown(300);

	$(".vd-novoCalculoDo").data("session",true);
	window.location.hash = "2:SelecaoDestino";
	
	if(typeof window.dataLayer !== "undefined") {
		window.dataLayer.push({
			'event': 'step_change_home',
			'step': 'SelecaoDestino'
		});
	}
};
vdViagem.PrefillHome = function(param) {
	var container = "#vd-skin-container",
		defaultParam = {};

		defaultParam.ufOrigem = param.calculo.codigoUfOrigem;
		defaultParam.codDestinoPais = param.calculo.codigoPaisDestino+"|"+param.calculo.codigoContinenteDestino+"|"+param.calculo.descricaoPaisDestino;
		defaultParam.dataIda = param.calculo.dataSaidaProponente;
		defaultParam.dataVolta = param.calculo.dataRetornoProponente;

	$.extend(defaultParam,param);

	window.setTimeout(function(){
		$(".vd-selItem[data-value='"+defaultParam.ufOrigem+"']",container).trigger("click");
		$("option[value='"+defaultParam.codDestinoPais+"']","#fl-entry-destino").prop("selected",true).trigger("change");

		if(defaultParam.codDestinoPais != "28-4")
			$(".vd-btn-internacional").trigger("click");
		else 
			$(".vd-btn-nacional").trigger("click");
		
		// vdViagem.Step3Startup(true);
		
		$("#vd-cal-ida",container).datepicker("setDate", defaultParam.dataIda).find(".ui-datepicker-current-day").trigger("click");
		$("#vd-cal-volta",container).datepicker("setDate", defaultParam.dataVolta).find(".ui-datepicker-current-day").trigger("click");

		for(var i=0;i<defaultParam.calculo.qtdPassagMenorSetentaAnos;i++) {
			$("#vd-qtdPassagMenorSetentaAnos .vd-add").trigger("click");
		}

		for(var i=0;i<defaultParam.calculo.qtdPassagMaiorSetentaAnos;i++) {
			$("#vd-qtdPassagMaiorSetentaAnos .vd-add").trigger("click");
		}
		
		// vdViagem.Step4Startup(true);

		//vdViagem.Step5Startup(true)
		//Pre-fill Primeiro País
		var cSelectedList = [];
		cSelectedList.push({
			codigoPaisDestino: param.calculo.codigoPaisDestino,
			codigoContinenteDestino: param.calculo.codigoContinenteDestino,
			name: param.calculo.descricaoPaisDestino
		});

		/* Pre-fill Múltiplos Países */
		var addMore = $(".viagem-btn-destino .ps-btn"),
			countrySelector = "#fl-entry-destino-adicional_",
			qtdCountries = 0,
			country = $("select[name='destino-pais']:eq(0) option:selected").text(),
			txtCountryInfo = $("#vd-top-step1-destino");

		for(var i=2,l=20;i<=l;i++) {
			var cSelector = eval("param.calculo.descricaoPaisDestinoAdicional"+i);
			if(typeof cSelector != "undefined") {
				var cValue = eval("param.calculo.codigoPaisDestinoAdicional"+i)+"|"+eval("param.calculo.codigoContinenteDestinoAdicional"+i)+"|"+eval("param.calculo.descricaoPaisDestinoAdicional"+i);
				addMore.trigger("click");
				$("option[value='"+cValue+"']",countrySelector+i).prop("selected",true);

				var currentSelector = countrySelector+i;
				triggerSelectCountry(currentSelector);
				
				var cValueMultiselect = cValue,
					cValueMultiselect = cValueMultiselect.split("|");

				cSelectedList.push({
					codigoPaisDestino: cValueMultiselect[0],
					codigoContinenteDestino: cValueMultiselect[1],
					name: cValueMultiselect[2]
				});
				
				qtdCountries++;
			}
		}

		function triggerSelectCountry(nm) {
			window.setTimeout(function() {
				$(nm).trigger("change");
			},450);
		}

		window.count = qtdCountries;

		$(".viagem-select-multidestinos").flexdatalist("reset").val(JSON.stringify(cSelectedList)).flexdatalist({
			minLength: 1,
			textProperty: '{name}',
			valueProperty: ["codigoPaisDestino","codigoContinenteDestino","name"],
			selectionRequired: true,
			visibleProperties: ["name"],
			searchIn: 'name',
			noResultsText: 'N&atilde;o h&aacute; resultados para o destino "{keyword}"',
			data: param.countries,
			normalizeString: function (string) {
				return latinize(string);
			}
		}).on("change:flexdatalist",function (obj,v,t,o) {
			vdViagem.SetCountriesSelected(obj,v,t,o);
    	});

		if(qtdCountries > 0 && param.calculo.codigoPaisDestino != "28")
			country = country + " (+"+qtdCountries+")";
		else
			country;

		txtCountryInfo.text(country);
		
		$("#vd-calc-step2, #vd-calc-step3").find(".vd-submitRow").stop(true,true).hide();
		$("#vd-calc-step4").find(".vd-submitRow").show();
	},100);
};
vdViagem.ClearPais = function() {
	//$(".vd-remove-destiny","#viagem-cttDestinos").trigger("click");
	$(".flexdatalist-multiple li.value .fdl-remove, #cttFrm [data-cloneremove]").trigger("click");
	//window.setTimeout(function(){$("select[name='destino-pais'] option[value='28|4|Brasil']").eq(0).prop("selected",true).trigger("change")},100);
}
vdViagem.Step2InternationalCountriesSelect = function() {
	if($(".viagem-select-destinos:visible").eq(0).val() == "28|4|Brasil") {
		//$("#vd-calc-step2 .viagem-btn-destino").slideDown("fast");
		$(".viagem-select-destinos").val("");
	}
}
vdViagem.HomeSlides = function() {
	var defaultCfg = {
		arrows: false,
		dots: true,
		mobileFirst: true,
		responsive: [
			{
				breakpoint: 1024,
				settings: "unslick"
			},
			{
				breakpoint: 600,
				settings: "unslick"
			},
			{
				breakpoint: 480, 
				settings: {
					slidesToShow: 1, 
					slidesToScroll: 1, 
					arrows: false
				}
			}
		]
	};

	vdViagem.SlickSlides(".vd-lp-block-itens",defaultCfg);
}
vdViagem.HomeFaq = function() {
	$(".vd-faq-question").on("click.pslp",function(e) {
		e.preventDefault();
		var li = $(this).parent(),
			ctt = $(this).next();
		
		$(this).parent().toggleClass("vd-faq-item-opened");

		if(ctt.is(":visible"))
			ctt.slideUp("fast");
		else
			ctt.slideDown("fast");
	});
}
vdViagem.HomeValidateStep1 = function(frm) {
	var rtn = true,
		destino = $(".viagem-select-multidestinos").val(),
		data = $(".vdo_startup__form--calendar").val(),
		passageiros = $(".vdo_startup__form_passenger--total").val();

	
	if(destino == '' || data == '' || passageiros == '') {
		rtn = false;
		psLib.NotifyShowHide("error:Preencha todos os campos de destino para iniciar sua cota&ccedil;&atilde;o",4000);
	} else {
		vdViagem.Step2Startup();
	} 

	return rtn; 
}